<template>
  <div>
    A venir
  </div>
</template>

<script>
export default {
  name: 'Residents',
}
</script>
